<template>
    <div class="testimoniBody">
        <b-container>
             <b-row>
                <b-col>
                   <div class="titleTestimoni"><h2>{{data.testimoniTitle}}</h2></div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/andrylie-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-andrylie-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/dewi-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-dewi-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/erna-'+bahasa+'.jpeg'"  width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-erna-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/jansen-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-jansen-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/kartini-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-kartini-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/lussiana-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-lussi-'+bahasa+'.jpeg'" width="100%" desc="Testimoni Biogreen">
                </b-col>
                <b-col sm="4" class="cardsContent for_desktop"></b-col>
                <b-col sm="4" class="cardsContent for_tablet"></b-col>
                <b-col sm="4" class="cardsContent">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/inge-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                    <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/testimoni/desc-inge-'+bahasa+'.jpeg'" width="100%" alt="Testimoni Biogreen" desc="Testimoni Biogreen">
                </b-col>
            </b-row>
            <b-row >
                <b-col class="mulaiJualanButton for_desktop">
                    <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
                </b-col>
                <b-col class="mulaiJualanButton for_tablet">
                    <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
                </b-col>
                <b-col class="mulaiJualanButton for_mobile">
                    <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="80%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            bahasa:'id',
            data:{
                testimoniTitle:null,
                imageMulaiJulan:"mulaiJualan_id.png"
            },
            username:null,
            noTelp:'6281298339718'
        }
    },
    mounted(){
        axios.get(`https://api.bisnisonlinebgs.com/api/content/member/getMemberById${window.location.pathname}`).then(response => {
            this.noTelp = response.data.data[0].mobile_no
        })

        this.username = window.location.pathname.replace("/", "");
        if(this.username == ''){
            this.username = 'happy'
        }

        this.bahasa = localStorage.getItem('bahasa')
        this.data.imageMulaiJulan = `mulaiJualan_${this.bahasa}.png`

        axios.get(`../../lang_${this.bahasa}.json`).then(response => {
            // console.log(response.data.data[0].general[0].testimoniTitle)
            this.data.testimoniTitle = response.data.data[0].general[0].testimoniTitle
        })
    }
}
</script>

<style>

</style>